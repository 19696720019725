
import { gsap } from 'gsap';
import {
  defineComponent, ref, watchEffect,
} from 'vue';
import { Waypoint } from 'vue-waypoint';

export default defineComponent({
  /* eslint-disable max-len */
  name: 'SvgBackground',
  props: {
    id: String,
  },
  components: {
    Waypoint,
  },
  setup() {
    const heroWave1 = ref('');
    const heroWave2 = ref('');
    const heroWave3 = ref('');
    const aboutWave1 = ref('');
    const aboutWave2 = ref('');
    const aboutWave3 = ref('');
    const servicesWave1 = ref('');
    const servicesWave2 = ref('');
    const servicesWave3 = ref('');
    const servicesWave4 = ref('');
    const stepsWave1 = ref('');
    const stepsWave2 = ref('');
    const stepsDrop1 = ref('');
    const stepsDrop2 = ref('');
    const stepsDrop3 = ref('');
    const reviewsWave1 = ref('');
    const reviewsWave2 = ref('');
    const reviewsWave3 = ref('');
    const directorWave1 = ref('');
    const directorWave2 = ref('');
    const directorWave3 = ref('');
    const bonusesWave1 = ref('');
    const bonusesWave2 = ref('');
    const vimeoWave1 = ref('');
    const vimeoWave2 = ref('');

    const animate = (bgSet: (() => void)[]) => {
      if (Array.isArray(bgSet)) {
        bgSet.forEach((animateThing: (() => void)) => {
          if (typeof animateThing === 'function') animateThing();
        });
      }
    };
    // const BgBack = (id: string) => {
    //   bgSet.forEach((animateThing: CallableFunction) => animateThing());
    // };
    const gsapAnimate = (vector: string, id: string, from: gsap.TweenVars, to: gsap.TweenVars) => {
      // console.log(vector, id, from, to); return;

      if (vector === 'forward') {
        gsap.fromTo(id, from, to);
      } else {
        gsap.fromTo(id, to, from);
      }
    };
    const BgByVector = (vector: string, id: string): (() => void)[] => {
      switch (id) {
        case 'hero':
          return [
            () => gsapAnimate(vector, heroWave1.value, {
              y: 280,
              opacity: 0.4,
            }, {
              y: 110,
              opacity: 1,
              duration: 4,
            }),
            () => gsapAnimate(vector, heroWave2.value, {
              y: 50,
              opacity: 0.8,
            }, {
              y: 90,
              opacity: 1,
              delay: 2,
              duration: 3,
              ease: 'power2.inOut',
            }),
            () => gsapAnimate(vector, heroWave3.value, {
              y: 230,
              opacity: 0,
            }, {
              y: 60,
              opacity: 1,
              delay: 1,
              duration: 3,
            }),
          ];

        case 'about':
          return [
            () => gsapAnimate(vector, aboutWave1.value, {
              y: 120,
              scale: 1.4,
            }, {
              y: 0,
              scale: 1,
              duration: 1,
            }),
            () => gsapAnimate(vector, aboutWave2.value, {
              y: 50,
              scale: 2,
            }, {
              y: 0,
              scale: 1,
              duration: 2,
            }),
            () => gsapAnimate(vector, aboutWave3.value, {
              y: 60,
              scale: 1.4,
            }, {
              y: 0,
              scale: 1,
              delay: 0,
              duration: 1,
            }),
          ];

        case 'services':
        case 'preferences':
          return [
            () => gsapAnimate(vector, servicesWave1.value, {
              y: 80,
              x: 20,
              scale: 2,
            }, {
              x: 0,
              y: -20,
              scale: 1,
              duration: 2,
            }),
            () => gsapAnimate(vector, servicesWave2.value, {
              y: 50,
              scale: 1.4,
            }, {
              scale: 1,
              y: -20,
              duration: 2,
            }),
            () => gsapAnimate(vector, servicesWave3.value, {
              y: 60,
              scale: 1.3,
            }, {
              scale: 1,
              y: 30,
              delay: 0.3,
              duration: 1,
            }),
            () => gsapAnimate(vector, servicesWave4.value, {
              y: 120,
              scale: 1.4,
            }, {
              scale: 1,
              y: 60,
              duration: 2,
            }),
          ];

        case 'steps':
          return [
            () => gsapAnimate(vector, stepsWave1.value, {
              y: 0,
              scale: 2,
            }, {
              scale: 1,
              y: 100,
              duration: 1,
            }),
            () => gsapAnimate(vector, stepsWave2.value, {
              y: 0,
            }, {
              y: 60,
              duration: 1,
            }),
            () => gsapAnimate(vector, stepsDrop1.value, {
              y: 300,
              scale: 0,
            }, {
              scale: 1,
              y: 100,
              delay: 0.7,
              duration: 0.3,
            }),
            () => gsapAnimate(vector, stepsDrop2.value, {
              y: 300,
              scale: 0,
            }, {
              scale: 1,
              y: 100,
              delay: 0.9,
              duration: 0.3,
            }),
            () => gsapAnimate(vector, stepsDrop3.value, {
              y: 300,
              scale: 0,
            }, {
              scale: 0.8,
              y: 60,
              delay: 0.83,
              duration: 0.4,
            }),
          ];

        case 'reviews':
          return [
            () => gsapAnimate(vector, reviewsWave1.value, {
              y: 0,
              scale: 1.6,
            }, {
              scale: 1,
              y: 20,
              duration: 1,
            }),
            () => gsapAnimate(vector, reviewsWave2.value, {
              y: 0,
              scale: 1.6,
            }, {
              scale: 1,
              y: 20,
              duration: 1,
            }),
          ];

        case 'director':
          return [
            () => gsapAnimate(vector, directorWave1.value, {
              y: 280,
              opacity: 0.4,
            }, {
              y: 110,
              opacity: 1,
              duration: 2,
            }),
            () => gsapAnimate(vector, directorWave2.value, {
              y: 50,
              opacity: 0.8,
            }, {
              y: 90,
              opacity: 1,
              delay: 2,
              duration: 1,
              ease: 'power2.inOut',
            }),
            () => gsapAnimate(vector, directorWave3.value, {
              y: 0,
              opacity: 1,
            }, {
              y: 60,
              opacity: 1,
              duration: 1,
            }),
          ];

        case 'bonuses':
          return [
            () => gsapAnimate(vector, bonusesWave1.value, {
              y: 0,
              scaleX: 1.2,
            }, {
              y: 50,
              scale: 1,
              duration: 1,
            }),
            () => gsapAnimate(vector, bonusesWave2.value, {
              y: 50,
              scaleX: 1.8,
            }, {
              y: 45,
              duration: 3,
              scaleX: 2.6,
              delay: 0.3,
            }),
          ];

        case 'vimeo':
          return [
            () => gsapAnimate(vector, vimeoWave1.value, {
              y: 0,
            }, {
              y: -50,
              duration: 1,
              delay: 0.3,
            }),
            () => gsapAnimate(vector, vimeoWave2.value, {
              scaleY: 1.4,
            }, {
              opacity: 1,
              scaleY: 1,
              duration: 1,
            }),
          ];

        default:
          return [] as (() => void)[];
      }
    };

    watchEffect(() => {
      if (heroWave1.value) {
        animate(BgByVector('forward', 'hero'));
      }
    });

    const isAnimatedForward = ref([] as string[]);

    const waypointChange = (vector: string, id: string, e: { [key: string]: string }) => {
      if (id === 'hero') return;

      if (vector === 'bottom' && e.going === 'IN' && e.direction === 'UP') {
        if (isAnimatedForward.value.includes(id)) return;

        // console.log('animating', id);
        animate(BgByVector('forward', id));
        isAnimatedForward.value.push(id);
      }

      if (vector === 'top' && e.going === 'IN' && e.direction === 'DOWN') {
        if (!isAnimatedForward.value.includes(id)) return;

        // console.log('animating', id);
        animate(BgByVector('back', id));
        isAnimatedForward.value = isAnimatedForward.value.filter((i) => i !== id);
      }
    };
    return {
      heroWave1,
      heroWave2,
      heroWave3,
      aboutWave1,
      aboutWave2,
      aboutWave3,
      servicesWave1,
      servicesWave2,
      servicesWave3,
      servicesWave4,
      stepsWave1,
      stepsWave2,
      stepsDrop1,
      stepsDrop2,
      stepsDrop3,
      reviewsWave1,
      reviewsWave2,
      reviewsWave3,
      directorWave1,
      directorWave2,
      directorWave3,
      bonusesWave1,
      bonusesWave2,
      vimeoWave1,
      vimeoWave2,

      waypointChange,
    };
  },

});
