import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_Body = _resolveComponent("Body")
  const _component_Footer = _resolveComponent("Footer")
  const _component_Order = _resolveComponent("Order")
  const _component_MeetingBooking = _resolveComponent("MeetingBooking")
  const _component_PresentationOrder = _resolveComponent("PresentationOrder")
  const _component_Toast = _resolveComponent("Toast")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createVNode(_component_Header)
      ]),
      _: 1
    }),
    _createVNode(_component_Body),
    _createVNode(_component_Footer),
    _createVNode(_component_Order),
    _createVNode(_component_MeetingBooking),
    _createVNode(_component_PresentationOrder),
    _createVNode(_component_Toast, {
      position: "bottom-left",
      group: "bl"
    })
  ], 64))
}